import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import Header from "./Header";
import {BrowserRouter, Link, Route, Switch, useLocation, HashRouter} from "react-router-dom";
import Footer from "./Footer";

import HomePage from "../pages/HomePage"

import Select, {components} from "react-select";
import {FaAngleDown, FaLayerGroup} from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import CartManager from "../util/CartManager";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import RegisterPageV2 from "../pages/RegisterPageV2";
import PaymentResult from "../pages/PaymentResult";
import LoginPage from "../pages/LoginPage";
import SearchResultPage from "../pages/SearchResultPage";
import ResultDetailPage from "../pages/ResultDetailPage";
import MemberPage from "../pages/MemberPage";
import ActivityPage from '../pages/ActivityPage'
import ActivityDetailPage from '../pages/ActivityDetailPage'
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import WelcomeMigrationPage from "../pages/WelcomeMigrationPage";

let requestCountDownTime = 0

function MainContainer(props) {
    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'}/>
                </components.DropdownIndicator>
            )
        );
    };

    let location = useLocation();

    const [isMobile, setMobile] = useState(window.innerWidth < 786);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const [isLoginShown, setLoginShown] = useState(false);
    const [isRegisterShown, setRegisterShown] = useState(false);

    const [user, setUser] = useState({});
    const [cart, setCart] = useState(CartManager.getCartContent());

    const [phoneNumber, setPhoneNumber] = useState('');

    const [isUpdateProfileShown, setUpdateProfileShown] = useState(false);

    const [registerAlert, setRegisterAlert] = useState(null)

    const [isCartShown, setCartShown] = useState(false)
    const [isProfileShown, setProfileShown] = useState(false)
    const [isCategoryShown, setCategoryShown] = useState(false)
    const [isSellerShown, setSellerShown] = useState(false)

    let currDate = new Date()

    const handleResize = () => {
        setMobile(window.innerWidth < 786)
        setWindowWidth(window.innerWidth)
    }

    const [isCartOpen, setCartOpen] = useState(false)
    const [isDrawerOpen, setDrawerOpen] = useState(false)

    return <Container
        fluid
        style={{
            minHeight: '100vh',
            fontFamily: "Signika"
        }}>

        <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={registerAlert !== null}
            onClose={() => setRegisterAlert(null)}
            message={registerAlert}
        />

        <div
            onMouseOverCapture={() => {
                setCategoryShown(false)
            }}
            onClick={() => {
                setCategoryShown(false)
            }}>
            <Header
                setDrawerOpen={(open) => setDrawerOpen(open)}
                setCategoryShown={setCategoryShown}
                isCartShown={isCartShown}
                setCartShown={setCartShown}
                isProfileShown={isProfileShown}
                setProfileShown={setProfileShown}
                isCartOpen={isCartOpen}
                setCartOpen={(open) => setCartOpen(open)}
                setSellerShown={setSellerShown}
                user={user}
                cart={cart}
                setCart={setCart}
                openUpdateProfile={() => {

                    setEmail(user.email)
                    setFullName(user.full_name)
                    setPhoneNumber(user.phone_num)
                    setAddress(user.address)
                    setPostalCode(user.zip_code)

                    setUpdateProfileShown(true)
                }}
                openLogin={() => setLoginShown(true)}
                openRegister={() => setRegisterShown(true)}
            />
        </div>

        <div
            onMouseOverCapture={() => {
                setCategoryShown(false)
            }}
            onClick={() => {
                setCategoryShown(false)
            }}
            style={{
                minHeight : "calc(100vh - 95px - 1em - 250px)"
            }}
        >
            {/*<HashRouter>*/}
                <Switch>
                    <Route exact path="/" component={HomePage}/>

                    <Route exact path="/search" component={SearchResultPage}/>
                    <Route exact path="/search/:advanced" component={SearchResultPage}/>

                    {/* <Route exact path="/register" component={RegisterPageV2}/> */}
                    
                    <Route
                        path="/kegiatan"
                        exact
                        component={ActivityPage}
                    />
                    <Route
                        path="/kegiatan/:id"
                        exact
                        component={ActivityDetailPage}
                    />
                    <Route exact path="/login" component={LoginPage}/>
                    <Route exact path="/result/:id" component={ResultDetailPage}/>
                    <Route exact path="/member/profile/:hpi_id" component={ResultDetailPage}/>
                    <Route exact path="/anggota/:hpi_id" component={ResultDetailPage}/>
                    <Route exact path="/payment-result/:invnumber" component={PaymentResult}/>
                    <Route exact path="/payment-result" component={PaymentResult}/>
                    <Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
                    <Route path="/member" component={MemberPage}/>
                    <Route path="/account-activation" component={WelcomeMigrationPage}/>
                    

                    <Route exact path={"*"} component={() => {
                        window.location.href = "/"
                        return null;
                    }}/>
                </Switch>
            {/*</HashRouter>*/}
        </div>
        <div style={{
            paddingTop: 100
        }}>
        </div>
        <Footer isMobile={isMobile}/>

    </Container>


}

export default MainContainer
